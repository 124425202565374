import { createContext, useEffect, useState } from "react";
import useLoginStore from "../hooks/useLoginStore";
import useUserStore from "../hooks/useUserStore";
import {
  AppShell, Navbar, Header, ScrollArea, Burger, MediaQuery, Text, UnstyledButton, Group, Button, ActionIcon, Box, Avatar, Collapse, Input, Flex, Image, ThemeIcon, Divider
} from '@mantine/core';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import {
  BuildingCottage, Users, Ballon, Home as HomeIcon, MoodKid, Tool, Logout, List, BuildingBank, Search, Keyboard, LayoutSidebarRightCollapse, ListSearch, UserSearch, Briefcase, Settings, File, Report, Message
} from 'tabler-icons-react';
import '../style.css';
import { NavUser } from "./nav_users";
import MainLinks from "./nav_button";
import SearchBar from './searchBar';
import { useGlobalStateWithLocalStorage } from "../stores/store";

const links = [
  // { icon: <HomeIcon size={32} />, color: 'blue', label: 'Home', href: '/' },
  { icon: <Users size={18} />, color: 'blue', label: 'Candidate', href: '/candidate' },
  { icon: <Briefcase size={18} />, color: 'blue', label: 'Job', href: '/job' },
  { icon: <BuildingBank size={18} />, color: 'green', label: 'Client', href: '/client' },
  { icon: <File size={25} />, color: 'red', label: 'Files', href: '/objects' },
  { icon: <Message size={25} />, color: 'red', label: 'Noti', href: '/notifications' },
];

const adminLinks = [
  { icon: <Tool size={25} />, color: 'violet', label: 'Users', href: '/admin/user' },
  { icon: <Report size={25} />, color: 'red', label: 'Report', href: '/admin/report' },
  { icon: <BuildingCottage size={25} />, color: 'yellow', label: 'Field', href: '/admin/field' },
  { icon: <Keyboard size={25} />, color: 'pink', label: 'Control', href: '/admin/keywords' },
];

function Home() {
  const navigate = useNavigate();
  const logout = useLoginStore();
  const token = localStorage.getItem('token')
  const [user, fetchUser] = useUserStore();
  const [menuOpened, setMenuOpened] = useState(false);
  const [userHiddenMenuOpened, setUserHiddenMenuOpened] = useState(true);
  const [searchOpened, setSearchOpened] = useState(false);
  const [active, setActive] = useState(null);
  const isTokenExpired=(token)=> {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  }
  const handleSearch = () => {
    setMenuOpened(false);
    setSearchOpened(!searchOpened);
    // search result
  };

  useEffect(() => {
    fetchUser();
  }, []);
  useEffect(() => {
    if (isTokenExpired(token)) {
      navigate('/login')
    }
  })

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed={false}
      padding="0"
      sx={(theme) => ({
        backgroundColor: theme.colors.brand[0],
      })}
      header={
        <Header height={80} p="xs"
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            borderColor: theme.colors.brand[2],
          })}>
          <Flex justify='space-between' sx={{ height: '100%' }}>
            <Flex justify='center' direction='column'>
              <Image src="/logo.jpeg" fit='contain'
                onClick={() => navigate('/')}
                sx={(theme) => ({
                  cursor: 'pointer',
                  '.mantine-Image-imageWrapper': {
                    width: 300,
                  },
                })}
              />
            </Flex>
            {/* <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
              <Flex sx={{ flexGrow: 1, justifyContent: 'center' }}>
                <SearchBar />
              </Flex>
            </MediaQuery> */}
            <MediaQuery largerThan='md' styles={{ display: 'none' }}>
              <Flex gap='xs'>
                {/* <UnstyledButton
                  onClick={handleSearch}
                >
                  <Search
                    color='black'
                    mr='xl'
                    sx={{ alignItems: 'center' }}
                    height='100%'
                  />
                </UnstyledButton> */}
                <Burger
                  opened={menuOpened}
                  onClick={() => {
                    setMenuOpened((o) => !o);
                    setSearchOpened(false);
                  }
                  }
                  size="md"
                  color='black'
                  mr="xl"
                  sx={{ alignSelf: 'center' }}
                />
              </Flex>
            </MediaQuery>
          </Flex>
        </ Header>
      }
      navbar={
        <Navbar p="md" fixed={false} hidden={!menuOpened} width={{ base: '100%', md: !menuOpened ? 160 : 82.5 }}
          sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            borderColor: theme.colors.brand[2],
            transition: 'all 0.2s'
          })}>

          <Navbar.Section grow mx="-xs" px="xs" sx={{ overflowX: 'hidden !important' }}>
            {links.map(link => <MainLinks {...link} key={link.label} active={active} setActive={setActive} />)}
            {user?.roles?.includes('SUPER_ADMIN') && adminLinks.map(link => <MainLinks {...link} key={link.label} active={active} setActive={setActive} />)}
          </Navbar.Section>
          <MediaQuery smallerThan='sm' styles={{ display: 'block' }}>
            <Navbar.Section>
              {/* <Flex justify='start' pt='sm'>
                <Box
                  sx={{ cursor: 'pointer', paddingLeft: 10 }}
                  onClick={() => {
                    setMenuOpened(!menuOpened);
                    setSearchOpened(false);
                  }}
                >
                  {
                    menuOpened ?
                      <LayoutSidebarLeftCollapse color='#3d3d3d' /> :
                      <LayoutSidebarRightCollapse color='#3d3d3d' />
                  }
                </Box>
              </Flex> */}
              <NavUser name={user.name} picture={user.picture} handleOnClick={() => setUserHiddenMenuOpened(!userHiddenMenuOpened)} />
              <Button
                leftIcon={<Settings />}
                color="blue"
                size="xs"
                radius="md"
                sx={(theme) => ({
                  marginBottom: theme.spacing.xs,
                  marginTop: theme.spacing.md
                })}
                onClick={() => navigate('profile')}
              >
                Edit Profile
              </Button>

              <Button
                leftIcon={<Logout />}
                color="red"
                size="xs"
                radius="md"
                sx={(theme) => ({
                  marginBottom: theme.spacing.xs,
                })}
                onClick={() => logout()}
              >
                Logout
              </Button>
            </Navbar.Section>
          </MediaQuery>
        </Navbar>

      }
    // navbar={
    //   <Navbar p="md" hiddenBreakpoint="sm" hidden={!menuOpened} width={{ sm: 200, lg: 300 }}>
    //     <Text>Application navbar</Text>
    //   </Navbar>
    // }
    >
      {/* Search Box */}
      {/* <Collapse in={searchOpened} transitionDuration={100}>
        <SearchBar />
      </Collapse> */}
      {/* <ScrollArea sx={(theme) => ({
        padding: `0`,
				height: `calc(100vh - 80px)`,
      })}> */}
      <Outlet />
      {/* </ScrollArea> */}
    </AppShell >
  );
}

const UIMessageContext = createContext(null);

function ProtectedRoute() {
  const isAuthenticated = useGlobalStateWithLocalStorage('isAuthenticated');
  const [uiMessage, setUIMessage] = useState({ msg: null });
  useUserStore();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <UIMessageContext.Provider value={{ uiMessage, setUIMessage }}>
    <Home />
  </UIMessageContext.Provider>;
}

export default ProtectedRoute;
