import React from 'react';
import { ChevronRight } from 'tabler-icons-react';
import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme } from '@mantine/core';

export function NavUser({
  name, picture
}) {
  const theme = useMantineTheme();

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${
          theme.colors.brand[3]
        }`,
      }}
    >
      <UnstyledButton
        sx={{
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colors.black[0],
        }}
      >
        <Group>
          <Avatar
            src={picture}
            radius="md"
          />
          <Box sx={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name}
            </Text>
          </Box>
        </Group>
      </UnstyledButton>
    </Box>
  );
}