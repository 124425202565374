import { ActionIcon, SimpleGrid, Text, Input, Badge, Flex, CopyButton, MultiSelect, Box } from '@mantine/core';
import { IconX, IconCheck, IconEdit, IconPlus, IconCopy } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useHover } from '@mantine/hooks';

const EditableMultiSelect = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [dirtyState, setDirtyState] = useState(props.value || []);
	const [value, setValue] = useState(props.value || []);
	const [data, setData] = useState(props.data || []);

	useEffect(() => {
		// console.log(props.data)
		// console.log(props.value)
		setData(props.data);
		setValue(props.value);
		setDirtyState(props.value);
	}, [props.value]);

	function handleEdit(v) {
		setDirtyState(v);
		setEditMode(true);
	}

	function handleCancel() {
		setEditMode(false);
		setDirtyState(value);
	}

	function handleSubmit() {
		props.onEdit(dirtyState);
		setEditMode(false);
		setValue(dirtyState);
	};

	function addNewOption(value) {
		const newData = [...data, { value: value, label: value }];
		setData(newData);
	}

	return (
		<Flex justify="space-between" sx={() => ({
			margin: '0.5rem 0',
		})}>
			<Box sx={() => ({
				width: '20%',
				fontSize: '1rem',
				fontFamily: 'monospace',
				marginRight: '1rem',
			})}>
				{props.label || 'LABEL'}
			</Box>
			<Box sx={() => ({
				alignSelf: 'left',
				flex: 1,
			})}>
				<MultiSelect
					readOnly={!!props.readonly}
					data={data}
					value={dirtyState}
					searchable={!props.readonly && !!props.addNewOption}
					onChange={(e) => {
						setEditMode(true);
						handleEdit(e)
					}}
					onKeyDown={(e) => {
						// let user to add new option, till hit space / enter
						if (e.key === 'Enter' || e.key === ' ') {
							e.preventDefault();
							if (e.target.value === '') return;
							addNewOption(e.target.value);
						}
						// if user hit backspace, and the input is empty, remove the last option
						if (e.key === 'Backspace' && e.target.value === '') {
							e.preventDefault();
							const newValue = [...value];
							newValue.pop();
							setValue(newValue);
							props.onEdit(newValue);
						}
					}}
				/>
				{editMode && <>
				<Flex>
					<ActionIcon
						name="close"
						color="red"
						onClick={() => handleCancel(false)}
					>
						<IconX size={16} />
					</ActionIcon>
					<ActionIcon
						name="check"
						color="green"
						onClick={() => handleSubmit()}
					>
						<IconCheck size={16} />
					</ActionIcon></Flex>
				</>}
			</Box>
		</Flex>
	);

};

export default EditableMultiSelect;