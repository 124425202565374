import React from 'react';
import { useEffect, useState } from 'react';
import { Badge, Paper, Title, Text, Group, Stack, Divider } from '@mantine/core';

const ParsingResult = ({
	candidateId,
	loadCandidateCVData,
	overrideCandidateData,
	isNewCandidate,
	setCandidate,
}) => {

	const [candidateData, setCandidateData] = useState({});
	const [loading, setLoading] = useState(false);

	async function overrideAllAvailableData(data) {
		console.log('override all available data');
		console.log('data', data);

		var candidateData = {}
		for (const key in data.data.data) {
			const d = data.data.data;
			switch (key) {
				case 'firstName':
					candidateData = {
						...candidateData,
						'Name': `${d.firstName} ${d.middleName || ''} ${d.lastName || ''}`,
						'FirstName': d.firstName,
					}
					break;
				case 'LastName':
					candidateData = {
						...candidateData,
						'Name': `${d.firstName || ''} ${d.middleName || ''} ${d.lastName}`,
						'LastName': d.lastName,
					}
					break;
				case 'phoneNumber':
					candidateData = {
						...candidateData,
						'PhoneNumber': d.phoneNumber,
					}
					break;
				case 'email':
					candidateData = {
						...candidateData,
						'Email': d.email,
					}
					break;
				case 'linkedin':
					candidateData = {
						...candidateData,
						'Linkedin': d.linkedin,
					}
					break;
				case 'nationality':
					candidateData = {
						...candidateData,
						'Nationality': d.nationality,
					}
					break;
				case 'location':
					candidateData = {
						...candidateData,
						'Location': d.location,
					}
					break;
				case 'skills':
					if (d.skills == "null" || d.skills == null || d.skills == undefined) {
						break;
					}
					if (d.skills.length > 0) {
						candidateData = {
							...candidateData,
							'Skills': d.skills,
						}
					}
					break;
				case 'experience':
					if (d.experience.length > 0) {
						candidateData = {
							...candidateData,
							'CurrentPosition': d.experience[0].position,
							'LatestCompany': d.experience[0].companyName,
						}
					}
					break;
				default:
					break;
			}
		}
		console.log('candidateData', candidateData);
		setCandidate(
			candidateData,
		)
	}

  useEffect(() => {
    if (!candidateId) {
			console.error('No candidateId');
			return;
		}

    let isCancelled = false;
    let timeoutId;

    const fetchCandidate = async () => {
      if (isCancelled) return;

      setLoading(true);
      try {
        const data = await loadCandidateCVData(candidateId);
        if (isCancelled) return;

        if (data.created_at === data.updated_at) {
          timeoutId = setTimeout(fetchCandidate, 1000);
        } else {
          setCandidateData(data);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          console.error("Error fetching candidate data:", error);
          setLoading(false);
        }
      }
    };

    fetchCandidate();

    return () => {
      isCancelled = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [candidateId, loadCandidateCVData]);

	useEffect(() => {
			if (isNewCandidate) {
				setTimeout(() => {
					overrideAllAvailableData(candidateData);
				}, 500);
			}
	}, [candidateData]);

	return <>
		{candidateId && (loading || !candidateData.data) && <div>loading...</div>}
		{!loading && !!candidateData.data && DisplayParsedInfo(candidateData.data.data, overrideCandidateData)}
	</>
}
const DisplayParsedInfo = (data, overrideCandidateData) => {
	return <div>
		<ResumeDisplay {...data} overrideCandidateData={overrideCandidateData} />
	</div>

}

const formatDate = (dateString) => {
  if (!dateString) return 'Present';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
};

function EducationDisplay({ education }) {
  return (
    <>
      <Title order={3}>Education</Title>
			{(!education || education.length === 0) && <Text>No education</Text> || <>
      <Stack>

        {education?.sort((a, b) =>{
					return !a.EndDate || a.EndDate > b.EndDate ? -1 : 1;
				}).map((edu, index) => (
          <>
            <Text weight={700}>{edu.degree} </Text>
            {edu.major && <Text size="sm">{edu.major} </Text>}
            <Text size="sm">{edu.schoolName} </Text>
            <Text size="sm"> { formatDate(edu.StartDate)} - {formatDate(edu.EndDate)}</Text>
            {index === education.length - 1 && <Divider my="sm" />}
						</>
        ))}
      </Stack></>}
    </>
  );
}

function ExperienceDisplay({ experience, overrideCandidateData }) {
  return (
    <>
      <Title order={3}>Experience</Title>
			{(!experience || experience.length === 0) && <Text>No experience</Text> || <>
      <Stack>
        {experience?.sort((a, b) =>{
					return !a.EndDate || a.EndDate > b.EndDate ? -1 : 1;
				}).map((exp, index) => (
					<>
						<Text weight={700}>{exp.position} {index === 0 && <Override {...{
							label: 'CurrentPosition',
							value: `${exp.position}`,
							overrideCandidateData,
						}} />} </Text>
            <Text size="sm">{exp.companyName} {index === 0 && <Override {...{
							label: 'LatestCompany',
							value: `${exp.companyName}`,
							overrideCandidateData,
						}} />}</Text>
            <Text size="sm">{formatDate(exp.startDate)} - {formatDate(exp.endDate)}</Text>
            {index === experience.length - 1 && <Divider my="sm" />}
					</>
        ))}
      </Stack></>}
    </>
  );
}

function Override({
	label,
	value,
	overrideCandidateData,
}) {
	return <button onClick={() => overrideCandidateData(label, value)}>Accept</button>
}

function ResumeDisplay({
	firstName,
	middleName,
	lastName,
	education,
	experience,
	phoneNumber,
	email,
	linkedin,
	nationality,
	location,
	skills,
	overrideCandidateData,
}) {
  return (
    <Paper withBorder shadow="sm" p="md">
			<Text>First Name: {middleName} {firstName} <Override {...{
				label: 'FirstName',
				value: `${middleName || ''} ${firstName || ''}`,
				overrideCandidateData,
			}}/></Text> 
			<Text>Last Name: {lastName} <Override {...{
				label: 'LastName',
				value: `${lastName || ''}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Full Name: {lastName} {middleName} {firstName} <Override {...{
				label: 'Name',
				value: `${lastName || ''} ${middleName || ''} ${firstName || ''}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Phone Number: {phoneNumber} <Override {...{
				label: 'PhoneNumber',
				value: `${phoneNumber}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Email: {email} <Override {...{
				label: 'Email',
				value: `${email}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Linkedin: {linkedin} <Override {...{
				label: 'Linkedin',
				value: `${linkedin}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Nationality: {nationality} <Override {...{
				label: 'Nationality',
				value: `${nationality}`,
				overrideCandidateData,
			}}/></Text>
			<Text>Location: {location} <Override {...{
				label: 'Location',
				value: `${location}`,
				overrideCandidateData,
			}}/></Text>
			{ skills !== 'null' && skills !== null && skills !== undefined && skills.length > 0 &&
			<Text>Skills: {
			  skills?.map(s => <Badge key={s} color="blue" variant="outline">{s}</Badge>) || 'N/A' 
			} { skills?.length && <Override {...{
				label: 'Skills',
				value: skills,
				overrideCandidateData,
				}}/> }</Text> || <Text>Skills: N/A</Text>
			}
      <Divider my="md" />
      <EducationDisplay education={education} override={Override} />
      <Divider my="md" />
      <ExperienceDisplay experience={experience} override={Override} overrideCandidateData={overrideCandidateData} />
    </Paper>
  );
}

export default ParsingResult;