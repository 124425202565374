import React, { useEffect, useCallback, useState } from "react"
import dayjs from 'dayjs';
import { DateTimePicker } from '@mantine/dates';
import { Trash } from 'tabler-icons-react';
import { IconTrash, IconEdit, IconX } from '@tabler/icons';
import { Group, Space, Button, ActionIcon, Table, Box, Modal, Checkbox, Badge, Container, Input, Accordion } from "@mantine/core";
const ShareGroup = () => {
    const localGroup = localStorage.getItem('groupList')
    const shareGroupData = localStorage.getItem('shareGroupData')
    const [groupList, setGroupList] = useState([])
    const [localGroupList, setLocalGroupList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [checkList, setCheckList] = useState([])
    useEffect(() => {
        if (localGroup === null) {
            setLocalGroupList([])
        } else {
            setLocalGroupList(JSON.parse(localGroup))
        }
    }, [localGroup])
    useEffect(() => {
        if (shareGroupData === null) {
            setGroupList([])
        } else {
            setGroupList(JSON.parse(shareGroupData))
        }
    }, [shareGroupData])
    const showGroupModal = () => {
        setShowModal(true)
    }
    const dateChange = (id, date, name) => {
        const newGroupList = groupList
        newGroupList.forEach((item) => {
            if (item.name === name) {
                const dateString = dayjs(date).format('YYYY-MM-DD HH:mm')
                const newUserList = item.userList
                newUserList.forEach((jtem) => {
                    if (jtem.id === id) {
                        jtem.expiryDate = dateString
                    }
                })
            }
        })
        localStorage.setItem('shareGroupData', JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
    }
    const deleteGroup = (name) => {
        const newGroupList = groupList
        let sIndex = 0;
        newGroupList.forEach((item, index) => {
            if (item.name === name) {
                sIndex = index
            }
        })
        newGroupList.splice(sIndex, 1)
        localStorage.setItem('shareGroupData', JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
    }
    function AccordionControl(accordionProps) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Accordion.Control {...accordionProps} />
                <ActionIcon size="lg" onClick={() => deleteGroup(accordionProps.children[0])}>
                    <IconTrash color='red' size="1rem" />
                </ActionIcon>
            </Box>
        );
    }
    const rows = (data, name) => {
        return data.map((element) => (
            <tr key={element.id}>
                <td>{element.name}</td>
                <td>{element.email}</td>
                <td>{element.roles?.map(r => <Badge key={r.toString()} radius="xs" variant="outline">{r}</Badge>)}</td>
                <td>
                    <DateTimePicker
                        valueFormat='YYYY-MM-DD HH:mm'
                        value={new Date(element.expiryDate)}
                        onChange={(value) => dateChange(element.id, value, name)}
                        placeholder="Pick date"
                    />
                </td>
            </tr>
        ));
    }
    const addGroup = () => {
        if (!checkList.length) return
        const oldList = groupList
        const newGroupList = []
        checkList.forEach((item) => {
            if (oldList.filter((jtem) => jtem.name === item.name).length === 0) {
                newGroupList.push({
                    ...item
                })
            }
        })
        localStorage.setItem('shareGroupData', JSON.stringify([...oldList, ...newGroupList]))
        setGroupList([...oldList, ...newGroupList])
        setCheckList([])
        setShowModal(false)
    }
    const selectBox = (e, data) => {
        if (e.currentTarget.checked) {
            setCheckList([...checkList, data])
        } else {
            let _index = 0
            checkList.forEach((item, index) => {
                if (item.name === data.name) {
                    _index = index
                }
            })
            const newCheckList = checkList
            newCheckList.splice(_index, 1)
            setCheckList(JSON.parse(JSON.stringify(newCheckList)))
        }
    }
    return (
        <>
            <Button size="xs" onClick={() => showGroupModal()}>Add Group</Button>
            <Space h='xs' />
            <Modal size='md' opened={showModal} onClose={() => setShowModal(false)} zIndex={202}>
                <div style={{ maxHeight: '30rem' }}>
                    <Group>
                        {
                            localGroupList.map((item) =>
                                <Checkbox
                                    checked={checkList.filter((jtem) => jtem.name === item.name).length}
                                    onChange={(e) => selectBox(e, item)}
                                    label={item.name}
                                    size="md"
                                />
                            )
                        }
                    </Group>
                </div>
                <Space h='xs' />
                <Group position="right">
                    <Button onClick={() => addGroup()}>Done</Button>
                </Group>
            </Modal>
            <Container px='0'>
                <Accordion chevronPosition="left" variant="contained" radius="md">
                    {
                        groupList.map((item) =>
                            <Accordion.Item key={item.name} value={item.name}>
                                <AccordionControl>{item.name}/{item.userList.length}</AccordionControl>
                                <Accordion.Panel>
                                    {
                                        item.userList.length ? (
                                            <Table style={{ background: '#fff' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Roles</th>
                                                        <th>Expiry Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{rows(item.userList, item.name)}</tbody>
                                            </Table>
                                        ) : null
                                    }
                                    <Space h='xs' />
                                </Accordion.Panel>
                            </Accordion.Item>
                        )
                    }
                </Accordion>
            </Container>
        </>
    )
}
export default ShareGroup