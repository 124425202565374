import { ActionIcon, Text, Input, Badge, Flex, CopyButton, Grid, Box } from '@mantine/core';
import { IconX, IconCheck, IconEdit, IconPlus, IconCopy } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useHover } from '@mantine/hooks';
import dayjs from 'dayjs';

const EditableText = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [value, setValue] = useState(props.value || null);
	const { hovered, ref } = useHover();

	function handleEdit() {
		props.onEdit(value);
		setEditMode(false);
	};

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	return (
		<Flex justify="space-between" ref={ref} sx={() => ({
			margin: '0.5rem 0',
		})}>
			<Box sx={() => ({
				width: '20%',
				fontSize: '1rem',
				fontFamily: 'monospace',
				marginRight: '1rem',
			})}>
				{props.label || 'LABEL'}
			</Box>
			<Box sx={() => ({
				alignSelf: 'left',
				flex: 1,
			})}>
				{
					editMode ?
						<Input
							placeholder=""
							defaultValue={value}
							onChange={(e) => setValue(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleEdit();
								}
							}}
						/> :
						value ?
							<CopyButton value={value}>
								{({ copied, copy }) => (
									<Flex gap={14} justify="start">
										<Text weight={300} size="md" sx={
											(theme) => ({
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												'@media (max-width: 768px)': {
													fontSize: '0.8rem',
												}
											})
										}>
											{props.isDate ? dayjs(value).format('DD MMM YYYY h:mma') : (props.currency ? Number(value).toLocaleString() : value)}
										</Text>
										{
											props.copyable &&
											<ActionIcon
												onClick={copy}
												color={copied ? 'green' : 'gray'}
												variant="transparent"
											>
												{copied ?
													<Text weight={300} size="sm" sx={(theme) => ({
														whiteSpace: 'nowrap',
														'@media (max-width: 768px)': {
															fontSize: '0.8rem',
														}
													})}>Copied</Text> :
													<IconCopy size={16} />
												}
											</ActionIcon>
										}
									</Flex>
								)}
							</CopyButton>
							:
							props.readonly ? <></> : <Badge
								variant="outline"
								leftSection={<IconPlus size={10} />}
								w={100}
								sx={(theme) => ({
									cursor: 'pointer',
									'&:hover': {
										backgroundColor: theme.colors.green[0],
									}
								})}
								onClick={() => setEditMode(true)}
							>
								Add
							</Badge>
				}
			</Box>
			{
				editMode ?
					<>
						<ActionIcon
							name="close"
							color="red"
							onClick={() => setEditMode(false)}
						>
							<IconX size={16} />
						</ActionIcon>
						<ActionIcon
							name="check"
							color="green"
							onClick={props.onEdit ? () => handleEdit() : () => setEditMode(false)}
						>
							<IconCheck size={16} />
						</ActionIcon>
					</> :
					props.readonly ? <></> : <ActionIcon
						name="edit"
						color="blue"
						onClick={() => setEditMode(true)}
						sx={{
							visibility: true && value && props.onEdit ? 'visible' : 'hidden',
						}}
					>
						<IconEdit size={16} />
					</ActionIcon>
			}
		</Flex>
	);

};

export default EditableText;