import { Input, Box, Popover, Button, Text, MediaQuery, Flex, Avatar, Stack, Group, UnstyledButton } from '@mantine/core';
import { useState, useRef } from 'react';

const handleResultCardClick = () => {
  console.log('clicked');
};

const SearchResults = () => {
  return (
    <Box sx={{ width: '100%' }}>
      {/* <Text pb="sm">Search results</Text> */}
      <ResultCard />
      <ResultCard />
    </Box>
  );
};

const ResultCard = () => {
  return (
    <UnstyledButton
      sx={{ width: '100%' }}
      onClick={handleResultCardClick}
    >
      <Flex gap="xs" pb="sm" justify='space-between'>
        <Group gap="xs">
          <Avatar src={null} alt="no image here" />
          <Stack spacing={0}>
            <Text size="xs">Job Title</Text>
            <Text size="xs" c='dimmed'>Company Name</Text>
          </Stack>
        </Group>
        <Text size="xs" c="dimmed">
          Owner
        </Text>
      </Flex>
    </UnstyledButton >
  );
};


const SearchInput = (props) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <Popover
          width={466}
          position="bottom"
          shadow="md"
          opened={opened}
          closeOnClickOutside={false}
          closeOnEscape={false}
          zIndex={100}
        >
          <Popover.Target>
            <Input sx={{
              width: 500,
              alignSelf: 'center',
            }}
              placeholder="Search Jobs..."
              size='md'
              onClick={() => setOpened(true)}
              onBlur={() => setOpened(false)}
            />
          </Popover.Target>
          <Popover.Dropdown>
            <SearchResults />
          </Popover.Dropdown>
        </Popover>
      </MediaQuery>
      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <Flex
          p='md'
          sx={{
            position: 'fixed',
            top: 80,
            left: 0,
            right: 0,
            zIndex: 100,
            backgroundColor: 'white',
          }}>
          <Input sx={{
            width: '100%',
            alignSelf: 'center',
          }}
            placeholder="Search"
            size='lg'
            radius={0}
          />
        </Flex>
      </MediaQuery>
      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <Flex
          p="md"
          sx={{
            position: 'fixed',
            top: 150,
            left: 16,
            right: 16,
            zIndex: 100,
            backgroundColor: 'white',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: '4px'
          }}>
          <SearchResults />
        </Flex>
      </MediaQuery>

    </>
  );
};

export default SearchInput;