import { useState } from 'react';
import { ActionIcon, Box, Flex } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { IconEdit, IconCheck } from '@tabler/icons';

const TextEditor = (props) => {
  const [editMode, setEditMode] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: props.content,
  });
  return (
    <Box
      p="sm"
      sx={(theme) => ({
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.brand[0],
        border: `1px solid ${theme.colors.brand[1]}`,
        fontFamily: 'barlow, sans-serif',
      })}
    >
      {editMode ? (
        <>
          <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
          <Flex mt="sm" justify="flex-end">
            <ActionIcon
              onClick={() => {
                setEditMode(false);
                props.onEdit(editor.getHTML());
              }}
              variant="outline"
              color="green"
              size="sm"
            >
              <IconCheck size={16} />
            </ActionIcon>
          </Flex>
        </>
      ) :
        <div style={{
          position: 'relative'
        }}>
          <ActionIcon
            onClick={() => {
              setEditMode(true);
            }}
            variant="transparent"
            color="gray"
            size="sm"
            sx={{ position: 'absolute', top: 0, right: 0, zIndex: 99 }}
          >
            <IconEdit size={16} />
          </ActionIcon>
          <div style={{ padding: '1rem', color: props.content ? 'black' : 'gray' }}>
            <span dangerouslySetInnerHTML={{ __html: props.content ? props.content : "No content" }} />
          </div>
        </div>
      }
    </Box>
  );
};

export default TextEditor;