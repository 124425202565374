import skills from "../components/skills";

export const fields = [
	{ name: 'candidate.id::text', label: 'ID', inputType: 'text' },
	{ name: 'candidate.name', label: 'Name', inputType: 'text' },
	{ name: 'birthdate', label: 'Birthdate', inputType: 'date' },
	{ name: 'first_name', label: 'First Name', inputType: 'text' },
	{ name: 'last_name', label: 'Last Name', inputType: 'text' },
	{ name: 'nationality', label: 'Nationality', inputType: 'text' },
	{ name: 'location', label: 'Location', inputType: 'text' },
	{ name: 'remarks', label: 'Remarks', inputType: 'textarea' },

	{ name: 'candidate.email', label: 'Email', inputType: 'text' },
	{ name: 'phone_number', label: 'Phone Number', inputType: 'text' },
	{ name: 'wechat', label: 'Wechat', inputType: 'text' },
	{ name: 'whatsapp', label: 'Whatsapp', inputType: 'text' },
	{ name: 'skype', label: 'Skype', inputType: 'text' },
	{ name: 'homeTel', label: 'Home Telephone', inputType: 'text' },

	// { name: 'created_at', label: 'Created At', inputType: 'date' },
	// { name: 'updated_at', label: 'Updated At', inputType: 'date' },
	{ name: 'owner.name', label: 'Owner', inputType: 'text' },
	{ name: 'source', label: 'Source', inputType: 'text' },

	{ name: 'Skills', label: 'Skills (select)', valueEditorType: 'select', inputType: 'text', operators: [
		{name: '@>', label: 'has'},
	], values: [
		...skills.map((skill) => ({name: skill, label: skill}))
	]},
	{ name: ' Skills', label: 'Skills (input)', inputType: 'text', operators: [
		{name: '@>', label: 'has'},
	]},
	// { name: 'Industries', label: 'Industries', inputType: 'text' },
	// { name: 'Specialities', label: 'Specialities', inputType: 'text' },
	// { name: 'Functions', label: 'Functions', inputType: 'text' },
	// { name: 'FunctionDescription', label: 'Function Description', inputType: 'textarea' },
	{ name: 'Function', label: 'Function', valueEditorType: 'multiselect', inputType: 'text', operators: [
		{name: 'in', label: 'in'},
	],
		values: [
			{ label: "Sales & Marketing", name: "f9bd69a7-b48f-49cb-916a-fe9103af8fd7" },
			{ label: "Supply Chain Management", name: "8f7bdeb0-e6a3-4ba3-a4f5-48f03fb57d15" },
			{ label: "Others", name: "d82276fa-d7ed-4bd1-9863-5168e68add90" },
			{ label: "General Management", name: "0e0dbba2-b84b-4eb3-9607-f384f9a27047" },
			{ label: "Financial Officer", name: "f31adf45-6f04-4594-be1e-65f3204ba991" },
			{ label: "IT Officer", name: "39800e0f-86e0-4156-aa38-2f031462a48d" },
			{ label: "Human Resources", name: "03de8c94-a474-46eb-8c4e-bb0111c49f9f" },
			{ label: "Engineering", name: "bc61cb6d-3e84-4998-a2c7-1900347dffec" }
	]
	},
	{ name: 'function_description', label: 'Function Description', inputType: 'textarea' },
	{
		name: 'Speciality', label: 'Speciality', valueEditorType: 'multiselect', inputType: 'text', operators: [
			{name: 'in', label: 'in'},
		],
		values: [
				{ name: "b61ac7fe-aaeb-468a-b3d1-b34daf701085", label: "Consumer - Consumer" },
				{ name: "3ab752fe-ba09-4d92-a3a7-b5a69981e75e", label: "Consumer - Consumer Products" },
				{ name: "2fd62899-bd3c-427e-ac44-e13131c5f234", label: "Consumer - Digital Consumer" },
				{ name: "d1eb7801-7154-46eb-bd17-2698e93607a2", label: "Consumer - Media & Entertainment" },
				{ name: "dc32f17c-efac-4cc5-9432-96881d6d8988", label: "Consumer - Retail" },
				{ name: "1e6a2e92-7e64-4fa6-aa18-fe69d4a3cf2a", label: "Consumer - Sports" },
				{ name: "dd6da451-66e9-4d2a-9638-e527bfa2c3c6", label: "Consumer - Travel, Hospitality & Leisure" },
				{ name: "78cf7c53-1350-43bc-907a-f17f149ea4ee", label: "Financial - Asset Management & Alternative Investment" },
				{ name: "051f22bc-5827-49b7-a557-516475bd3599", label: "Financial - Banking & Markets" },
				{ name: "5124d732-c3f8-4397-8ffc-c01e4fc50a40", label: "Financial - Consumer & Commercial Banking" },
				{ name: "cbb233ea-017d-4505-ae4c-48e134995215", label: "Financial - Digital" },
				{ name: "17d9df90-86c1-4268-a62c-eb4ab70fb834", label: "Financial - Financial" },
				{ name: "552f67eb-da51-40a1-86a0-522b0356a391", label: "Financial - Fintech" },
				{ name: "3849c2a7-2102-48ef-80cb-f44efab7984d", label: "Financial - Insurance" },
				{ name: "4752faa7-8321-4e0e-b5b9-f8560705f67d", label: "Financial - Real Estate" },
				{ name: "1e4cd826-f20b-46eb-9a74-5ed920ce963e", label: "Financial - Wealth Management" },
				{ name: "8ec69c88-f7c5-4a48-b812-3ca52be26a33", label: "Healthcare - Academic Medicine" },
				{ name: "f3800d91-43d1-4671-87cf-f67c2b57df17", label: "Healthcare - For-Profit & Private Capital" },
				{ name: "96dc6cea-d82e-449b-9516-a8bba4d793c8", label: "Healthcare - Healthcare" },
				{ name: "572f5319-f187-4fda-a77b-3c0fd29c0a9b", label: "Healthcare - Managed Care/Payer" },
				{ name: "43228777-5ae8-42d3-acb8-02991cc0d32a", label: "Healthcare - Technology & Services" },
				{ name: "4dd291f3-3155-46f0-b7f2-19557c9165b7", label: "Industrial - Industrial" },
				{ name: "6026f573-9eb6-4cf4-9396-d24c44a820c6", label: "Industrial - Agribusiness" },
				{ name: "5af5198d-9335-4151-a486-475720bca44e", label: "Industrial - Industrial Manufacturing" },
				{ name: "e4bffbc5-6a75-4cf0-a760-0e3d6205e94f", label: "Industrial - Infrastructure" },
				{ name: "6163c116-3ab5-4759-8d59-a37b5be135fc", label: "Industrial - Natural Resources" },
				{ name: "09baf7f9-aad4-421f-8e60-a10a53aa8b1e", label: "Industrial - Mobility" },
				{ name: "8360ed7f-99e6-40c3-9077-c1c7a6607947", label: "Life Science - Biotechnology" },
				{ name: "21df0a80-ac75-492f-a11c-843e5abc5333", label: "Life Science - Contract Services" },
				{ name: "82073bcc-b058-4182-ac48-eb0b9286635c", label: "Life Science - Life Science" },
				{ name: "8341ed5f-d81b-4169-b332-5d34342282b7", label: "Life Science - Medical Devices" },
				{ name: "5d98089d-e629-4169-9701-eb69eb2062d6", label: "Life Science - Pharmaceuticals" },
				{ name: "b8bdc3b4-ba51-4bfa-862b-8a1e2f8e0c62", label: "Technology - Cloud" },
				{ name: "d6f99123-2920-475b-95c8-ead9bafb1510", label: "Technology - Communications" },
				{ name: "c82f7390-3412-4f83-b23e-07c4572248cd", label: "Technology - Digital Technology" },
				{ name: "687d5c69-2cb9-4424-9e58-f68940ea5092", label: "Technology - IT Professional Services" },
				{ name: "a205cd50-9be8-4c14-ab50-469135622d6e", label: "Technology - Software" },
				{ name: "eccaf868-b437-4dca-9418-3510ae1d728f", label: "Technology - Systems & Devices" },
				{ name: "bf6fe8ff-9553-44c6-b76f-78d0704f6bcc", label: "Technology - Technology" }
		]
	},
	{ name: 'master', label: 'Master Degree', inputType: 'text' },
	{ name: 'phd', label: 'PhD', inputType: 'text' },
	{ name: 'degree', label: 'Degree', inputType: 'text' },
	{ name: 'diploma', label: 'Diploma', inputType: 'text' },
	{ name: 'university', label: 'University', inputType: 'text' },

	{ name: 'current_position', label: 'Current Position', inputType: 'text' },
	{ name: 'latest_company', label: 'Latest Company', inputType: 'text' },
	{ name: 'referrer_name', label: 'Referrer Name', inputType: 'text' },
	{ name: 'referrer_email', label: 'Referrer Email', inputType: 'text' },
	{ name: 'current_salary', label: 'Current Salary', inputType: 'text' },
	{ name: 'current_currency_code', label: 'Current Currency Code', inputType: 'text' },
	{ name: 'expected_salary', label: 'Expected Salary', inputType: 'text' },
	{ name: 'expected_currency_code', label: 'Expected Currency Code', inputType: 'text' },

	{ name: 'filename', label: 'Filename', inputType: 'text' },
	{ name: 'resume_id', label: 'Resume ID', inputType: 'text' },
	{ name: 'is_active', label: 'Is Active', inputType: 'text' },
];
