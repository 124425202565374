import { useState } from 'react';
import {
	createStyles,
	Table,
	UnstyledButton,
	Group,
	Text,
	Center,
	Checkbox,
	rem,
	Box,
	Pagination,
	Select,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
	th: {
		padding: '0 !important',
	},

	control: {
		width: '100%',
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,

		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
		},
	},

	icon: {
		width: rem(21),
		height: rem(21),
		borderRadius: rem(21),
	},
}));

function Th({ children, reversed, sorted, onSort }) {
	const { classes } = useStyles();
	const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
	return (
		<th className={classes.th}>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position="apart">
					<Text fw={500} fz="sm">
						{children}
					</Text>
					<Center className={classes.icon}>
						<Icon size="0.9rem" stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

function filterData(data, search) {
	const query = search?.toLowerCase().trim();
	return data.filter((item) =>
		keys(data[0]).some((key) => item[key]?.toLowerCase().includes(query))
	);
}

function sortData(
	data,
	payload
) {
	const { sortBy } = payload;

	if (!sortBy) {
		return filterData(data, payload.search);
	}

	return filterData(
		[...data].sort((a, b) => {
			if (payload.reversed) {
				return b[sortBy].localeCompare(a[sortBy]);
			}

			return a[sortBy].localeCompare(b[sortBy]);
		}),
		payload.search
	);
}

export default function CandidateTable({
	setLimit,
	limit,
	totalItems,
	setPage,
	activePage,
	data,
	sortBy,
	setSortBy,
	onCandidateClick,
	selections,
	setSelections,
}) {
	const { classes, cx } = useStyles();

	const toggleRow = (id) =>
		setSelections((current) =>
			current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
		);

	// const setSorting = (field ) => {
	//   const reversed = field === sortBy ? !reverseSortDirection : false;
	//   setReverseSortDirection(reversed);
	//   setSortBy(field);
	//   setSortedData(sortData(data, { sortBy: field, reversed, search }));
	// };

	// const handleSearchChange = (event) => {
	//   const { value } = event.currentTarget;
	//   setSearch(value);
	//   setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
	// };

	const rows = data && data.map((row) => {
		const selected = selections.includes(row.ID);
		return <tr key={row.ID} className={cx({ [classes.rowSelected]: selected })}>
			<td>
				<Checkbox
					checked={selections.includes(row.ID)}
					onChange={() => toggleRow(row.ID)}
					transitionDuration={0}
				/>
			</td>
			<td style={{cursor: "pointer"}} onClick={() => onCandidateClick(row.ID)}>{row.Name}</td>
			<td>{row.Email}</td>
			<td>{row.Owner.name}</td>
			<td>{dayjs(row.CreatedAt).format('DD MMM YYYY')}</td>
		</tr> || <></>;
	});

	// generalize the function for sorting
	const isSorted = (field) => {
		return sortBy.find(x => Object.keys(x)[0] === field) ? true : false;
	}
	const isReversed = (field) => {
		return sortBy.find(x => Object.keys(x)[0] === field) && sortBy.find(x => Object.keys(x)[0] === field)[field] === 'asc' ? true : false;
	}
	const setSorting = (field) => {
		// add cancel condition
		if (sortBy.find(x => Object.keys(x)[0] === field)) {
			if (sortBy.find(x => Object.keys(x)[0] === field)[field] === 'asc') {
				setSortBy([{ [field]: 'desc' }, ...sortBy.filter((item) => Object.keys(item)[0] !== field)]);
			} else {
				// remove the field from the array
				setSortBy([...sortBy.filter((item) => Object.keys(item)[0] !== field)]);
			}
		} else {
			setSortBy([{ [field]: 'asc' }, ...sortBy]);
		}
	}

	return (<>
		<Table fontSize="sm" horizontalSpacing="xs" verticalSpacing="xs" miw={700}>
			<thead>
				<tr>
					<Th width="1rem"></Th>
					<Th
						sorted={isSorted('Name')}
						reversed={isReversed('Name')}
						onSort={() => setSorting('Name')}
					>
						Name
					</Th>
					<Th
						sorted={isSorted('Email')}
						reversed={isReversed('Email')}
						onSort={() => setSorting('Email')}
					>
						Email
					</Th>
					<Th
						sorted={isSorted('Owner.name')}
						reversed={isReversed('Owner.name')}
						onSort={() => setSorting('Owner.name')}
					>
						Owner
					</Th>
					<Th
						sorted={isSorted('created_at')}
						reversed={isReversed('created_at')}
						onSort={() => setSorting('created_at')}
					>
						Created
					</Th>
				</tr>
			</thead>
			<tbody>
				{rows.length > 0 ? (
					rows
				) : (
					<tr>
						<td colSpan={5}>
							<Text weight={500} align="center">
								Nothing found
							</Text>
						</td>
					</tr>
				)}
			</tbody>
		</Table>
		<Box sx={() => {
			return {
				display: 'flex',
				justifyContent: 'center',
				marginTop: '1rem',
				border: '1px solid #e0e0e0',
				borderLeft: 0,
				borderRight: 0,
				minWidth:700
			};
		}}>
			<Box sx={() => ({
				padding: '0.5rem 1rem',
				borderRight: '1px solid #e0e0e0',
				flexDirection: 'row',
				display: 'flex',
				alignItems: 'center',
			})}>
				<Box sx={() => ({width: '100px', padding: '0 1rem'})}><Text size='sm'>Rows per page:</Text></Box>
				<Box sx={() => ({width: '80px'})}><Select size='sm'
				  onChange={setLimit}
					value={limit}
					data={[
						{ value: 10, label: 10 },
						{ value: 20, label: 20 },
						{ value: 50, label: 50 },
						{ value: 100, label: 100 },
						{ value: 150, label: 150 },
					]} />
				</Box>
			</Box>
			<Pagination size={window.innerWidth>720?'md':'xs'}  sx={() => ({ padding: '0 1rem' })} value={activePage} onChange={setPage} total={(totalItems / limit)} />
			<Box sx={() => ({
				padding: '0.5rem 1rem',
				borderLeft: '1px solid #e0e0e0',
			})}>
				<Text>Total: {totalItems || 0} </Text>
			</Box>
		</Box>
	</>);
}