import { ActionIcon, SimpleGrid, Text, Input, Badge, Flex, CopyButton, Select, Box } from '@mantine/core';
import { IconX, IconCheck, IconEdit, IconPlus, IconCopy } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useHover } from '@mantine/hooks';
import dayjs from 'dayjs';

const EditableSelect = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(props.value || null);
	const [dirtyState, setDirtyState] = useState(props.value || []);

	useEffect(() => {
		// console.log(props.data)
		// console.log(props.value)
		setValue(props.value);
		setDirtyState(props.value);
	}, [props.value]);

	function handleEdit(v) {
		setDirtyState(v);
		setEditMode(true);
	}

	function handleCancel() {
		setEditMode(false);
		setDirtyState(value);
	}

	function handleSubmit() {
		props.onEdit(dirtyState);
		setEditMode(false);
		setValue(dirtyState);
	};


  return (
		<Flex justify="space-between" sx={() => ({
			margin: '0.5rem 0',
		})}>
			<Box sx={() => ({
				width: '20%',
				fontSize: '1rem',
				fontFamily: 'monospace',
				marginRight: '1rem',
			})}>
				{props.label || 'LABEL'}
			</Box>
			<Box sx={() => ({
				alignSelf: 'left',
				flex: 1,
			})}>
				<Select
					readOnly={!!props.readonly}
					data={props.data ? props.data : []}
					value={dirtyState}
					searchable={true}
					onChange={(e) => {
						setEditMode(true);
						handleEdit(e)
					}}
				/>
      {editMode && <>
				<Flex>
					<ActionIcon
						name="close"
						color="red"
						onClick={() => handleCancel(false)}
					>
						<IconX size={16} />
					</ActionIcon>
					<ActionIcon
						name="check"
						color="green"
						onClick={() => handleSubmit()}
					>
						<IconCheck size={16} />
					</ActionIcon></Flex>
				</>}
		</Box>
	</Flex>
  );

};

export default EditableSelect;