import { Text } from "@mantine/core";
import React from "react";

const Header = (props) => <Text
component="span"
  // variant="gradient"
  // gradient={{ from: 'red', to: 'yellow', deg: 45 }}
weight={700}
  sx={(theme) => ({
    color: props.color ? props.color : theme.colors.black[0],
  fontSize: '150%',
  display: 'inline-block',
    padding: theme.spacing.md,
    paddingBottom: '0'
  // marginBottom: theme.spacing.sm
})}
>
{props.children}
</Text>

export default Header;