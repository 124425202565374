import { ThemeIcon, UnstyledButton, Flex, Text, MediaQuery, NavLink } from '@mantine/core';
import { Link } from "react-router-dom";


export default function MainLink({ icon, color, label, href, children, active, setActive }) {
  return (
    <NavLink
      key={label}
      component={Link}
      to={href}
      label={label}
      icon={icon}
      active={active === label}
      color={color}
      childrenOffset={0}
			variant="subtle"
      // defaultOpened
      onClick={() => {
        if (children && children.length > 0) return;
        setActive(label);
      }}
      sx={(theme) => ({
        '.mantine-NavLink-label': {
          'textOverflow': 'ellipsis',
        }
      })}
    >
      {children && children.length > 0 && children.map((child) => (
        <NavLink
          component={Link}
          to={child.href}
          label={child.label}
          icon={child.icon}
          active={active === child.label}
          color={child.color}
          onClick={() => {
            setActive(child.label);
          }}
        />
      ))



      }
    </NavLink>);
}