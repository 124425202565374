import Board from 'react-trello';
import './index.css';

export const KanbanBoard = (props) => {
  return (
    <Board
      className='board'
      data={props.data}
      cardDraggable={true}
      onDataChange={(newData) => props.onDataChange(newData)}
      onCardMoveAcrossLanes={(sourceLaneId, targetLaneId, cardId) => {props.onCardMoveAcrossLanes(sourceLaneId, targetLaneId, cardId)}}
      hideCardDeleteIcon={true}
    />
  );
};

export default KanbanBoard;