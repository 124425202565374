import { Center, Button, Table, Space, Pagination, Container, Text, Flex, Group, Menu, Input, Badge, Checkbox, Modal } from '@mantine/core';
import { Plus, Edit, Reload, Filter } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import useCURDStore from '../hooks/useCURDStore';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useState, useEffect } from 'react';
import QRCode from "react-qr-code";

const AdminListView = (props) => {
	const { page,
		totalPage,
		loading,
		resources,
		createResource,
		listResources,
		deleteResource,
		sortResources,
		searchResources,
		reloadResources, } = useCURDStore({
			resourcesName: props.resources,
			mock: props.mock || false,
			mockResources: props.mockResources || [],
			mockResource: props.mockResource || {},
			filter: props.filter
		});

	const navigate = useNavigate();
	const { pageName = '' } = props
	const [selectList, setSelectList] = useState([])
	const [sortStatus, setSortStatus] = useState({
		columnAccessor: 'CreatedAt',
		direction: 'desc',
	});
	const [records, setRecords] = useState(resources);
	const [filter, setFilter] = useState([]);
	const [search, setSearch] = useState('');
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		console.log(sortStatus);
		console.log('sortStatus changed', sortStatus.direction)
		sortResources(sortStatus.columnAccessor, sortStatus.direction);
		console.log('sortStatus changed', sortStatus.direction)
	}, [sortStatus]);

	useEffect(() => {
		setRecords(resources);
	}, [resources]);
	const selectListChange = (boo,name, email) => {
		if (boo) {
			setSelectList([...selectList, {email,name}])
		} else {
			let sIndex = 0
			const newSelectList = selectList
			newSelectList.forEach((item, index) => {
				if (item.email === email) {
					sIndex = index
				}
			})
			newSelectList.splice(sIndex, 1)
			setSelectList([...newSelectList])
		}
	}
	const createQRcode = () => {
		setShowModal(true)
	}
	const closeModal = () => {
		setShowModal(false)
		setSelectList([])
	}
	return (
		<Container
			sx={() => ({
				maxWidth: "none",
			})}
		>
			<div style={{ borderRadius: 3, backgroundColor: "white" }}>
				<Modal zIndex={201} size='100%' opened={showModal} onClose={closeModal} title="QRcode">
					<div style={{background:'#fff' }}>
						<Flex
							mih={50}
							gap="md"
							justify="flex-start"
							align="flex-start"
							direction="row"
							wrap="wrap"
						>
							{
								pageName === 'user' && showModal ?
									selectList.map((item) =>
										<div style={{ width: '200px' }}>
											<Center>{item.name}</Center>
											<Center>{item.email}</Center>
											<Center>
												<QRCode size={150} value={`${window.location.host}/autoLogin?u=${item}&p=${''}`} />
											</Center>
										</div>
									)
									: null
							}
						</Flex>
					</div>
				</Modal>
				<Group>
					{(!props.tool && props.createAllowed && !props.setCreateNewModal && (
						<Button
							size="xs"
							// variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
							onClick={() => navigate("create")}
							leftIcon={<Plus />}
						>
							Create
						</Button>
					)) ||
						(props.createAllowed && (
							<Button
								size="xs"
								// variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
								onClick={() => props.setCreateNewModal(createResource)}
								leftIcon={<Plus />}
							>
								Create
							</Button>
						))}
					{
						pageName == 'user' ? (
							<Button
								size="xs"
								disabled={!selectList.length}
								// variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
								onClick={() => createQRcode()}
							>
								Generate QR code
							</Button>
						) : null
					}
				</Group>

				{props.tool && (
					<Flex justify="space-between">
						{(props.createAllowed && !props.setCreateNewModal && (
							<Button
								size="xs"
								// variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
								onClick={() => navigate("create")}
								leftIcon={<Plus />}
							>
								Create
							</Button>
						)) ||
							(props.createAllowed && (
								<Button
									size="xs"
									// variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
									onClick={() => props.setCreateNewModal(createResource)}
									leftIcon={<Plus />}
								>
									Create
								</Button>
							))}
						<Group mr="xs">
							<Menu shadow="md" width={200}>
								<Menu.Target>
									<Button size="xs" variant="outline" leftIcon={<Filter />}>
										Filter
									</Button>
								</Menu.Target>

								<Menu.Dropdown>
									{props.schema?.map((resource, index) => {
										if (resource.filter) {
											return (
												<Menu.Item
													key={index}
													onClick={() => {
														setFilter((prev) => {
															if (prev.find((p) => p.name === resource.name)) {
																return prev.filter(
																	(p) => p.name !== resource.name
																);
															}
															return [...prev, resource];
														});
													}}
												>
													{resource.label}
												</Menu.Item>
											);
										}
									})}
								</Menu.Dropdown>
							</Menu>
							<Button
								size="xs"
								variant="outline"
								onClick={() => reloadResources()}
								leftIcon={<Reload />}
							>
								Reload
							</Button>
						</Group>
					</Flex>
				)}
				<Space h="md" />
				{filter.length > 0 ? (
					<>
						<Flex justify="space-between">
							<Group spacing={4}>
								{filter.map((fr, index) => {
									return (
										<Menu shadow="md" width={200}>
											<Menu.Target>
												<Button size="xs">
													<Text>{fr.label}</Text>
													{fr.userInput && (
														<>
															:{" "}
															<Text color="white" ml="xs" weight="lighter">
																{fr.userInput}
															</Text>
														</>
													)}
												</Button>
											</Menu.Target>

											<Menu.Dropdown>
												{/* TODO: debounce input value */}
												<Input
													placeholder="contains..."
													onChange={(e) => {
														const value = e.target.value;
														const data = resources.filter((r) => {
															setFilter((prev) => {
																const newPrev = [...prev];
																newPrev[index] = {
																	...newPrev[index],
																	userInput: value,
																};
																return newPrev;
															});
															if (r[fr.name]) {
																return r[fr.name]
																	?.toLowerCase()
																	.includes(value.toLowerCase());
															}
														});
														setRecords(data);
													}}
												/>
											</Menu.Dropdown>
										</Menu>
									);
								})}
							</Group>
							<Button
								mr="xs"
								size="xs"
								variant="outline"
								onClick={() => {
									setFilter([]);
								}}
							>
								Reset
							</Button>
						</Flex>
					</>
				) : null}
				{/*make a search bar */}
				{!props.noSearch ? (
					<Group>
						<Input
							style={{ width: "70vw" }}
							placeholder="Search..."
							onChange={(e) => setSearch(e.target.value)}
						/>
						<Button
							size="xs"
							variant="outline"
							onClick={() =>
								searchResources(search ? { name: search } : undefined)
							}
							leftIcon={<Filter />}
						>
							Search
						</Button>
					</Group>
				) : null}
				<Space h="md" />
				<DataTable
					highlightOnHover
					striped
					columns={props.schema?.map((resource, index) => {
						return {
							key: index,
							title: (
								<Text mr={resource.name == "actions" ? "xs" : "none"}>
									{resource.label}
								</Text>
							),
							width: resource.width,
							accessor: resource.name,
							textAlignment: resource.textAlignment || "left",
							sortable: resource.sort || false,
							render: (row) => {
								if (resource.name === 'name' && pageName === 'user') {
									return (
										<Checkbox
											onChange={(event) => selectListChange(event.currentTarget.checked,row[resource.name] ,row.email)}
											checked={selectList.map((jtem)=>jtem.email).includes(row.email)}
											label={row[resource.name]}
										/>
									)
								}
								if (resource.display) {
									return resource.display(row, { deleteResource });
								} else {
									return <>{row[resource.name]}</>;
								}
							},
						};
					})}
					records={records}
					sortStatus={sortStatus}
					onSortStatusChange={setSortStatus}
				/>
				<Space h="lg" />
				<Center>
					<Pagination
						total={totalPage}
						page={page}
						onChange={(page) => listResources({ _page: page })}
						radius="md"
					/>
				</Center>
			</div>
		</Container >
	);
};



export default AdminListView;