import React, { useEffect, useState } from "react";
import JobAddAssignModal from '../route/job/detailsTabs/jobAddAssignModal'
import dayjs from 'dayjs';
import { DateTimePicker } from '@mantine/dates';
import { Trash } from 'tabler-icons-react';
import { Group, Space, Button, ActionIcon, Table, Modal, Checkbox, Badge, Accordion } from "@mantine/core";
import ShareGroup from "./shareGroup";
const ShareButton = () => {
    const shareData = localStorage.getItem('shareData')
    const [shareModelOpen, setShareModelOpen] = useState(false);
    const [userList, setUserList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [checkList, setCheckList] = useState([])
    useEffect(() => {
        if (shareData === null) {
            setUserList([])
        } else {
            setUserList(JSON.parse(shareData))
        }
    }, [shareData])
    const closeASSignModal = () => {
        setShowModal(false)
        setCheckList([])
    }
    const showAssignModal = () => {
        setShowModal(true)
    }
    const addUser = () => {
        if (!checkList.length) return
        const oldList = userList
        const newAddUserList = []
        checkList.forEach((item) => {
            if (oldList.filter((jtem) => jtem.id === item.id).length === 0) {
                newAddUserList.push({
                    ...item,
                    expiryDate: dayjs().format('YYYY-MM-DD HH:mm')
                })
            }
        })
        // setUserList([...oldList, ...newAddUserList])
        localStorage.setItem('shareData', JSON.stringify([...oldList, ...newAddUserList]))
        setCheckList([])
        setShowModal(false)
    }
    const dateChange = (id, date) => {
        const dateString = dayjs(date).format('YYYY-MM-DD HH:mm')
        const newUserList = userList
        newUserList.forEach((item) => {
            if (item.id === id) {
                item.expiryDate = dateString
            }
        })
        setUserList([...newUserList])
        localStorage.setItem('shareData', JSON.stringify([...newUserList]))

    }
    const checkBoxChange = (e, o) => {
        if (e.currentTarget.checked) {
            setCheckList([...checkList, o])
        } else {
            let _index = 0
            checkList.forEach((item, index) => {
                if (item.id === o.id) {
                    _index = index
                }
            })
            const newCheckList = checkList
            newCheckList.splice(_index, 1)
            console.log(newCheckList)
            setCheckList(JSON.parse(JSON.stringify(newCheckList)))
        }
    }
    const doneShare = () => {

    }
    const userSetting = {
        resources: `users`,
        schema: [
            {
                name: '', label: '', textAlignment: 'left',
                display: (o) =>
                    <Checkbox checked={checkList.filter((item) => item.id === o.id).length} onChange={(e) => checkBoxChange(e, o)} />
            },
            { name: 'name', label: 'name', sort: true, filter: true },
            {
                name: 'email', label: 'email', sort: true, filter: true, display: (o) => <div>{
                    o.email
                } {!!o.isNew ? <Badge color="red">PENDING</Badge> : <></>}</div>
            },
            { name: 'roles', label: 'roles', sort: true, filter: false, display: (o) => <div>{o.roles.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</div> },
        ],
        action: [
        ],
        createAllowed: true,
    };
    const deleteUser = (id) => {
        const newUserList = userList
        let sIndex = 0;
        newUserList.forEach((item, index) => {
            if (item.id === id) {
                sIndex = index
            }
        })
        newUserList.splice(sIndex, 1)
        setUserList([...newUserList])
        localStorage.setItem('shareData', JSON.stringify([...newUserList]))
    }
    const rows = (data) => {
        return data.map((element) => (
            <tr key={element.id}>
                <td>{element.name}</td>
                <td>{element.email}</td>
                <td>{element.roles?.map(r => <Badge key={r.toString()} radius="xs" variant="outline">{r}</Badge>)}</td>
                <td>
                    <DateTimePicker
                        valueFormat='YYYY-MM-DD HH:mm'
                        value={new Date(element.expiryDate)}
                        onChange={(value) => dateChange(element.id, value)}
                        placeholder="Pick date"
                    />
                </td>
                <td>
                    <Group spacing={4} position="right" noWrap>
                        <ActionIcon
                            name="delete"
                            color="red"
                            onClick={() => deleteUser(element.id)}
                        >
                            <Trash size={16} />
                        </ActionIcon>
                    </Group>
                </td>
            </tr>
        ));
    }
    return (
        <>
            <Button size="xs" onClick={() => setShareModelOpen(true)}>Share</Button>
            <JobAddAssignModal {...userSetting} showModal={showModal} closeASSignModal={closeASSignModal} addJob={addUser} />
            <Modal opened={shareModelOpen} onClose={() => setShareModelOpen(false)} size="xl" title="Share">
                <div style={{ height: '40rem', width: '100%', minWidth: '700px', overflow: 'auto' }}>
                    <Button size="xs" onClick={() => showAssignModal()}>Add User</Button>
                    <Space h='xs' />
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Roles</th>
                                <th>Expiry Date</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody>{rows(userList)}</tbody>
                    </Table>
                    <Space h='xs' />
                    <ShareGroup />
                </div>
                <Space h='xs' />
                <Group position="right">
                    <Button onClick={doneShare}>Done</Button>
                </Group>
            </Modal>
        </>
    )
}
export default ShareButton