import { useState, useEffect } from 'react';
import axios from '../api';

export default function useCURDStore({
  resourcesName,
  fields,
  mock,
  mockResources,
  mockResource,
  defaultValue,
  filter = {},
}) {
  const [resources, setResources] = useState([]);
  const [resource, setResource] = useState(defaultValue || {});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [resourcesUpdated, setResourcesUpdated] = useState(false);
  // sorting target:
  // ?sort=field1,field2,field3&order=asc,desc,asc
  const [sort, setSort] = useState('CreatedAt');
  const [order, setOrder] = useState('desc');

  useEffect(() => {
    listResources({
      search,
      page: 1,
			sort: sort,
			order: order,
    });
    setPage(1);
  }, [resourcesName])

  async function reloadResources() {
    await listResources({
      _page: page,
      _search: search,
      sort: sort,
      order: order,
    });
  }

  async function sortResources(field, order) {
    setSort(field);
    setOrder(order);
    await listResources({ _page: page, _search: search, _sort: field, _order: order });
  }

  async function searchResources(search) {
    await listResources({ _page: page, _search: search, _sort: sort, _order: order });
    setSearch(search);
  }

  async function listResources({
    _page,
    _search,
    _sort,
    _order,
  }) {
    console.log('listResources', _page, _search, _sort, _order, sort, order)
    if (mock) {
      setResources(mockResources);
      setLoading(false);
      return;
    }
    let s = sort;
    let o = order;
    if (!!_sort) {
      s = _sort;
      o = _order;
    }
    if (!!_search) {
      setSearch(_search);
    } else {
      _search = search;
    }
    setLoading(true);
    setPage(_page);
    const { data } = await axios().get(`/${resourcesName}`, { params: { page: _page, search: _search, sort: s, order: o, ...filter } });
    setResources(data.data);
    setTotalPage(Math.ceil(data.total / data.limit));
    setLoading(false);
  }

  async function getResourceParam(params) {
    if (mock) {
      setResource({ data: mockResource });
      setLoading(false);
      return;
    }
    setLoading(true);
    const { data } = await axios().get(`/${resourcesName}`, { params: params });
    setResources(data.data);
    setLoading(false);
  }

  async function getResource(id) {
    if (mock) {
      setResource({ data: mockResource });
      setLoading(false);
      return;
    }
    setLoading(true);
    const { data } = await axios().get(`/${resourcesName}/${id}`);
    setResource(data);
    setLoading(false);
  }

  async function createResource(resource) {
    if (mock) {
      setResource(mockResource);
      setLoading(false);
      return;
    }
    setLoading(true);
    const { data } = await axios().post(`/${resourcesName}`, resource);
    // setResource(data.data);
    // setResourcesUpdated(true);
    // setTimeout(() => {
    //   setResourcesUpdated(false);
    // }, 3000);
    console.log(data)
    return data.id || data.ID || data.data.ID
  }

  async function updateResource(id, resource) {
    if (mock) {
      setResource(mockResource);
      setLoading(false);
      return;
    }
    setLoading(true);
    const { data } = await axios().post(`/${resourcesName}/${id}`, resource);
    setResource(data.data);
    setLoading(false);
    setResourcesUpdated(true);
    // await listResources({ _page: page, _search: search });
    // setTimeout(() => {
    //   setResourcesUpdated(false);
    // }, 3000);
  }

  async function deleteResource(id) {
    if (mock) {
      setLoading(false);
      return;
    }
    setLoading(true);
    await axios().delete(`/${resourcesName}/${id}`);
    setLoading(false);
    await listResources({ _page: page, _search: search, sort: sort, order: order });
  }

  function flush() {
    setResources([]);
    setResource({});
    setPage(1);
    setTotalPage(1);
    setSearch('');
    setLoading(true);
    setResourcesUpdated(false);
  }

  return {
    page,
    totalPage,
    loading,
    resource,
    resources,
    resourcesUpdated,
    listResources,
    getResource,
    getResourceParam,
    createResource,
    updateResource,
    deleteResource,
    reloadResources,
    searchResources,
    sortResources,
    flush,
  }
}