const axios = require('axios').default;

const fetcher = (form = false) => {
  let headers = {
    'authorization': 'Bearer ' + JSON.parse(
      localStorage.getItem('token')
    ),
  }
  if (form) {
    headers = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    }
  }
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers,
  })
}

export default fetcher;
