import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MantineProvider, Text } from '@mantine/core';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Notifications } from '@mantine/notifications';
import Login from './route/Login';
import AutoLogin from './route/autoLogin';
import SharePage from './route/sharePage';
import Home from './components/home';
import Index from './route/index';
import Onboarding from './route/onboarding';
import PopoverButton from './components/popoverButton';
import {Button} from '@mantine/core';
import api from './api';
import CandidatesList from './route/candidate/candidatesList';
import CandidateView from './route/candidate/candidateView';
// import CandidateEdit from './route/candidateEdit';
import CandidateCreate from './route/candidate/candidateCreate';
import CandidateAdvancedSearch from './route/candidateAdvancedSearch';
import CandidateAdvancedSearchSaved from './route/candidateAdvancedSearchSaved';
import JobsList from './route/job/jobList';
import JobView from './route/job/jobView';
import JobCreate from './route/job/jobCreate';
import ClientsList from './route/client/clientsList';
import ClientsView from './route/client/clientsView';
import ClientsCreate from './route/client/clientsCreate';
import EditProfile from './route/editProfile';
import UsersList from './route/usersList';
import UsersView from './route/usersView';
import ObjectList from './route/objectList';
import Report from './route/report/report';
import Field from './route/field/field';
import GroupUser from './route/group';
import Keywords from './route/keywords/keywords';
import StoreProvider from './stores/useStoreContext';
import ClientContactDetailView from './route/clientContacts/clientContactDetailView';
import ClientContactsListView from './route/clientContacts/clientContactsListView';
import ClientContactView from './route/clientContacts/clientContactView';
import ClientContactCreate from './route/clientContacts/clientsContactCreate';
import NotificationList from './route/notification';


const theme = {
  fontFamily: 'Roboto, sans-serif',
  colorScheme: 'light',
  colors: {
    white: ['#ffffff', '#f5f5f5', '#ebebeb', '#e1e1e1', '#d7d7d7', '#cdcdcd', '#c3c3c3', '#b9b9b9', '#afafaf', '#a5a5a5'],
    black: ['#000000', '#0a0a0a', '#141414', '#1f1f1f', '#292929', '#333333', '#3d3d3d', '#474747', '#515151', '#5b5b5b'],
    brand: ['#fff', '#f5f5f5', '#ebebeb', '#e1e1e1', '#d7d7d7', '#cdcdcd', '#c3c3c3', '#b9b9b9', '#afafaf', '#a5a5a5'],
    navy: ['#54b1e3', '#4ea5d3', '#4898c3', '#438cb4', '#3d80a4', '#387595', '#326986', '#2d5e78', '#28536a', '#23485c'],
    purple: ['#bcbeed', '#b0b2df', '#a4a5d0', '#9899c2', '#8c8db4', '#8082a7', '#757699', '#6a6b8c', '#5f607f', '#545572'],
    blue: ['#c9e7ee', '#b7d7df', '#a6c7cf', '#95b8c0', '#84a9b1', '#7399a3', '#638b94', '#527c86', '#426e79', '#31606b'],
    green: ['#afddb8', '#a0d1a9', '#91c69a', '#82ba8b', '#73af7c', '#64a46e', '#55985f', '#468d51', '#368243', '#257735'],
    pink: ['#edc3e1', '#e1b2d3', '#d5a1c6', '#ca90b9', '#be80ab', '#b3709e', '#a75f91', '#9b4f84', '#903e78', '#842c6b'],
  },
  primaryColor: 'black',
}

export const DisableOrEnableButton = ({ resourceName, o, reload }) => {
  const { disabled } = o;
  return <PopoverButton buttonName={disabled ? 'Enable' : 'Disable'} props={{ position: "left" }} buttonProps={{
    variant: "gradient", gradient: !!disabled ? { from: 'green', to: 'lime', deg: 60 } : { from: 'purple', to: 'red', deg: 60 },
  }}><Text>Are you sure?</Text><br /><Button onClick={() => disableOrEnable(disabled, o.id, resourceName, reload)}>Confirm</Button></PopoverButton>
}

export const disableOrEnable = (disabled, id, resourceName, reload) => {
  if (disabled) {
    return api().post(`/${resourceName}/${id}/enable`).then((o) => {
      reload()
    })
  }
  return api().post(`/${resourceName}/${id}/disable`).then((o) => {
    reload()
  })
}

const adminUserSetting = {
  resources: 'users',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'first_name', label: 'First Name'},
    {name: 'last_name', label: 'Last Name'},
    {name: 'email', label: 'Email'},
    {name: 'createdAt', label: 'Member Since'},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='users'
     o={o} reload={reload} /> }
  ]
}

const adminOrganizationSetting = {
  createAllowed: true,
  resources: 'organizations',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'following', label: 'Followers'},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='organizations' o={o} reload={reload} /> }
  ]
}

const adminEventSetting = {
  createAllowed: true,
  resources: 'events?all=true&disabled=false',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'from_date', label: 'Start date', display: (o) => <>{dayjs(o.from_date).format('DD MMM YYYY')}</>},
    {name: 'to_date', label: 'End date', display: (o) => <>{dayjs(o.to_date).format('DD MMM YYYY')}</>},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    {action: 'Attendees', label: 'Attendees', href: (o) => `${o.id}/attendees`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='events' o={o} reload={reload} /> }
  ]
}

const adminSchoolSetting = {
  createAllowed: true,
  resources: 'schools',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'total_hours', label: 'Total hours', display: (o) => <>{Math.ceil(o.total_hours / 60)}</>},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    {action: 'student', label: 'Students', href: (o) => `${o.id}/students`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='schools' o={o} reload={reload} /> }
  ]
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    children: [
      { path: '/', element: <Index /> },
      { path: 'profile', element: <EditProfile /> },
      { path: 'candidate', element: <CandidatesList /> },
      { path: 'candidate/create', element: <CandidateCreate /> },
      { path: 'candidate/:id', element: <CandidateView /> },
      { path: 'candidate/advanced-search', element: <CandidateAdvancedSearch /> },
      { path: 'candidate/advanced-search/saved', element: <CandidateAdvancedSearchSaved /> },
      { path: 'job', element: <JobsList /> },
      { path: 'job/create', element: <JobCreate /> },
      { path: 'job/:id', element: <JobView /> },
      { path: 'client', element: <ClientsList /> },
      { path: 'client/create', element: <ClientsCreate /> },
      { path: 'client/:id', element: <ClientsView /> },
      { path: 'client/:clientid/contact/create', element: <ClientContactCreate /> },
      { path: 'client/:clientid/contact/:id', element: <ClientContactView /> },
			{ path: 'admin/user', element: <UsersList /> },
			{ path: 'admin/user/:id', element: <UsersView /> },
			{ path: 'admin/user/group', element: <GroupUser /> },
			{ path: 'objects', element: <ObjectList /> },
			{ path: 'notifications', element: <NotificationList /> },
			{ path: 'admin/report', element: <Report /> },
			{ path: 'admin/field', element: <Field /> },
			{ path: 'admin/keywords', element: <Keywords /> },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'onboarding',
    element: <Onboarding />,
  },
  {
    path: 'autoLogin',
    element: <AutoLogin />,
  },
  {
    path: 'sharePage',
    element: <SharePage />,
  },
])

ReactDOM.render(
  <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
		<StoreProvider>
			<Notifications position="top-center" zIndex={2077} />
			<React.StrictMode>
				<RouterProvider router={router} />
			</React.StrictMode>
		</StoreProvider>
  </MantineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
