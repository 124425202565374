import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalStateWithLocalStorage, setGlobalStateWithLocalStorage } from '../stores/store';
import { jwtDecode } from 'jwt-decode';


export default function useUserStore() {
  const navigate = useNavigate();
  const user = useGlobalStateWithLocalStorage('user');
  async function loadUser() {
  }
  async function fetchUser() {
    // ##LOGIN COMPONENT
		const token = localStorage.getItem('token'); // Replace 'userToken' with your actual token key in localStorage
    if (token) {
      try {

        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        // Check if token has expired
        if (decodedToken.exp < currentTime) {
					// alert('Token expired')
					navigate('/login');
					return
        }
      } catch (error) {
        console.error('Invalid token', error);
				alert(error)
				navigate('/login');
				return
      }
    } else {
      console.log('No token found');
    }
  }
  useEffect(() => {
    loadUser();
  }, [])
  return [user, fetchUser];
}
