import React, { Suspense, lazy } from 'react';
import Resume from '../resume';
import { pdfjs } from 'react-pdf';
import { DocViewerRenderers } from "react-doc-viewer";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
const DocViewer = lazy(() => import('react-doc-viewer'));
// const LazyDocViewer = lazy(() => import('./LazyDocViewer'));

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.worker.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('DocViewer error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wr. Please try again later.</h1>;
    }

    return this.props.children; 
  }
}
const DefaultPdfLayout = ({
  fileUrl,
	candidateId,
  handleDrop
}) => {
	const key = fileUrl ? fileUrl : 'empty';
  return (
		<div
			key={fileUrl}
			style={{
				height: `100%`,
				width: "100%",
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<div style={{ textAlign: "center", marginTop: "25px" }}>
				<Resume
					candidateId={candidateId}
					handleDrop={async (resume) => {
						await handleDrop(resume);
					}}
				/>
			</div>
			<ErrorBoundary>
				<Suspense fallback={<div>Loading document viewer...</div>}>
					<DocViewer
						key={key}
						style={{ minHeight: "80vh", display: fileUrl ? "" : "none" }}
						documents={[{ uri: fileUrl }]}
						pluginRenderers={DocViewerRenderers}
					/>
				</Suspense>
			</ErrorBoundary>
		</div>
	);
};

export default DefaultPdfLayout;