import { useEffect, useState } from "react";
import { setGlobalStateWithLocalStorage } from "../stores/store";
import { useNavigate } from "react-router-dom";

export default function useLoginStore() {
  const navigate = useNavigate();
  useEffect(() => {
    // ##LOGIN COMPONENT
    // const login = JSON.parse(localStorage.getItem('login'));
    // if (!login) {
    //   return navigate('/login');
    // }
    // setLogin(login);
    // if (login?.user?.role?.length === 0) {
    //   navigate('/onboarding');
    // }
  }, [])
  function logout() {
    localStorage.removeItem('login');
    setGlobalStateWithLocalStorage('isAuthenticated', false);
    navigate('/login');
  }
  return logout;
}
