import React, { useEffect, useMemo, useState } from 'react';
import { formatQuery, QueryBuilder } from 'react-querybuilder';
import { fields } from '../definations/candidates';
import 'react-querybuilder/dist/query-builder.scss';
import './styles.query.scss'
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { Button, Tooltip } from '@mantine/core';
import { Anchor, ZoomReset } from 'tabler-icons-react';

function wrapSkillsValueInBucket(obj) {
	if (obj.field === "Skills") {
    return {
      ...obj,
      value: `["${obj.value}"]`,
    };
  } else if (obj.combinator && obj.rules) {
    return {
      ...obj,
      rules: obj.rules.map(wrapSkillsValueInBucket),
    };
  }
  return obj;
}

function ignoreCases(obj) {
	const ignoreCaseList = [
		'candidate.name',
		'first_name',
		'last_name',
		'nationality',
		'location',
		'remarks',
		'candidate.email',
		'phone_number',
		'wechat',
		'whatsapp',
		'skype',
		'homeTel',
		'source',
		'master',
		'phD',
		'degree',
		'diploma',
		'university',
		'currentCompany',
		'latestCompany',
		'referrerName',
		'currentSalary',
		'expectedSalary',
		'function_description',
		'owner.name',
	]
	if (ignoreCaseList.includes(obj.field)) {
		return {
			...obj,
			value: obj.value?.toLowerCase(),
			field: function () {
				// if field => X.Y we need to add "X"."Y", else "X"
				const field = obj.field.split('.');
				if (field.length === 1) {
					return `lower("${field[0]}")`;
				}
				return `lower("${field[0]}"."${field[1]}")`;
			}()
		};
	} else if (obj.combinator && obj.rules) {
		return {
			...obj,
			rules: obj.rules.map(ignoreCases),
		};
	}
	return obj;
}

export default function Query({
	initalIQuery,
	onSubmit,
	saveQuery,
	removeQuery,
	modifyQuery,
	isNewQuery,
}) {
	const [query, setQuery] = useState(initalIQuery || { combinator: 'and', rules: [] });
	useEffect(() => {
		console.log('initalIQuery', initalIQuery);
		setQuery(initalIQuery);
	}, [initalIQuery]);

  // set sortcut cmd + enter to submit
	useEffect(() => {
		const handleKeyDown = (e) => {
			console.log(e);
			if (e.keyCode === 13 && e.metaKey || e.keyCode === 13 && e.ctrlKey) {
				onSubmit(
					query,
					formatQuery({
						...query,
						rules: query.rules.map(wrapSkillsValueInBucket).map(ignoreCases),
					}, 'sql'),
					formatQuery(query, 'json_without_ids')
				)
			}
		}
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		}
	}, [query, onSubmit]);

	return (
		<div>
			<QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
				<QueryBuilder
					fields={fields}
					skill={[
						{ name: "Owner", label: "Owner", inputType: "text" },
						{ name: "Source", label: "Source", inputType: "text" },
					]}
					query={query}
					onQueryChange={(q) => setQuery(q)}
					showNotToggle
					controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
				/>
			</QueryBuilderDnD>
			<Tooltip label="Ctrl + Enter">
				<Button
					onClick={() => {
						onSubmit(
							query,
							formatQuery(
								{
									...query,
									rules: query.rules
										.map(wrapSkillsValueInBucket)
										.map(ignoreCases),
								},
								"sql"
							),
							formatQuery(query, "json_without_ids")
						);
					}}
				>
					<ZoomReset size={20} />
					&nbsp;Refine
				</Button>
			</Tooltip>
			{!isNewQuery && (
				<Button onClick={removeQuery}>
					<Anchor size={20} />
					&nbsp;Remove Search
				</Button>
			)}
			{isNewQuery && (
				<Button onClick={saveQuery}>
					<Anchor size={20} />
					&nbsp;Save Search
				</Button>
			)}
			{!isNewQuery && (
				<Button onClick={modifyQuery}>
					<Anchor size={20} />
					&nbsp;Modify Search
				</Button>
			)}
		</div>
	);
}