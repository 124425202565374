const skills = [
'Accounting - Audit (Int/Ext)',
'Accounting - Bkg & Fin Audit',
'Accounting - Biz Consultant',
'Accounting - Consolidation',
'Accounting - Cost',
'Accounting - Financial',
'Accounting - General',
'Accounting - Management',
'Accounting - Project',
'Accounting - Tax',
'Accounting - Treasury',
'Actuary',
'Admin - General',
'Admin - Facilities',
'Admin - Office Management',
'Admin - Office Relocate',
'Admin - Organisation & Method',
'Advertising',
'After Sales',
'Business/Market/Research',
'Architect',
'Banking - Account Servicing',
'Banking - Consumers',
'Banking - Correspondence',
'Banking - Investment',
'Banking - Middle Office',
'Banking - Private',
'Banking - Retail',
'Banking - Back Office',
'Banking - I/E Bills',
'Banking - Branding & Communica',
'Banking - Capital Market',
'Banking - Commodities',
'Banking - Compliance',
'Banking - Consumer Finance',
'Banking - Corporate Finance',
'Banking - Credit Analysis',
'Banking - Credit Control',
'Banking - Credit Card',
'Banking - Credit Risk',
'Banking - Custodian',
'Banking - Dealer / Trader',
'Banking - Derivatives',
'Banking - Direct Investment',
'Banking - Equities Sales',
'Banking - Event Management',
'Banking - Finance Institution',
'Banking - Financial Analyst',
'Banking - Financial Control',
'Banking - Fixed Income',
'Banking - Fund Management',
'Banking - General',
'Banking - Global Assets Mgt',
'Banking - IntAudit',
'Billing',
'Banking - Investment Advisory',
'Banking - Branch Operations',
'Banking - Product Development',
'Banking - Retail Management',
'Banking - Sales & Marketing',
'Banking - Loan admin',
'Banking - Merger',
'Banking - Market Risk',
'Banking - Marketing Research',
'Banking - Mortgage',
'Banking - Operation Risk',
'Banking - Portfolio Admin',
'Banking - Project Finance',
'Banking - Representative',
'Banking - Research',
'Banking - Risk Management',
'Banking - Settlement',
'Banking - Syndication Loans',
'Banking - Taxation',
'Banking - Trade Finance',
'Banking - Treasury Products',
'Banking - Trust',
'Business Development',
'Business Management',
'Buyer',
'Banking - Venture Capital',
'Banking - Wealth Management',
'Call Centre',
'Capital Equipment',
'Caterer',
'Change Management',
'Chef',
'Chemist',
'China Experience',
'Claims',
'Commodity',
'Compliance',
'Company Secretary',
'Consultant',
'Consumables',
'Contract Manufacturing',
'Corporate Development',
'Corporate Finance',
'Corporate Services',
'Crisis Management',
'CRM',
'Customer Service',
'Dealer',
'Dental',
'Design - Display',
'Design - Fashion',
'Design - General',
'Design - Graphic',
'Design - Interior',
'Design - Packaging',
'Device',
'Diagnostics',
'Digital Marketing',
'Disposables',
'Economist',
'Editors/Writers',
'Engineer - Quality Assurance',
'Engineer - Quality Control',
'Engineer - Quantity Survey',
'Engineer - Application',
'Engineer - Building',
'Engineer - Chemical',
'Engineer - Civil',
'Engineer - Design',
'Engineer - Electrical',
'Engineer - Electronics',
'Engineer - Hardware',
'Engineer - Industrial',
'Engineer - Marine',
'Engineer - Materials',
'Engineer - Mechanical',
'Engineer - Production',
'Engineer - Rsrch & Development',
'Engineer - Service',
'Engineer - Subscriber',
'Engineer - Sales',
'Environment Management',
'Equipment',
'ERP',
'Food & Beverage Management',
'Foreign Services',
'Freight',
'Fresh Graudate',
'Forex',
'General',
'General Management',
'Garment Accessories',
'Garment Asia Market',
'Garment Babies/Children',
'Garment EEC Market',
'Garment Mixed Products/Markets',
'Garment High Fashion',
'Garment Knit/Sweater',
'Garment Lingerie',
'Garment Outerwear',
'Garment Sportswear',
'Garment USA Market',
'Garment Woven',
'Garment Technologist',
'HR - Assessment Center',
'HR - C&B',
'HR - Campus Recruitment',
'HR - OrganisationalDevelopment',
'HR - Downsizing',
'HR - Employee Relations',
'HR - Expat',
'HR - General',
'HRIS',
'HR - Labour Relations',
'HR - Learning & Development',
'HR - Outplacement',
'HR - Payroll Mgt',
'HR - Performance Management',
'HR - Process Re-engineering',
'HR - Recruit',
'HR - Salary Survey',
'HR - Staff Handbook',
'HR - Strategic Planning',
'HR - Succession Planning',
'HR - Talent Management',
'Hygene',
'Imaging',
'IT-Security',
'IT-Business Analyst',
'IT-Business Consultant',
'IT-Computer Programming',
'IT-Customer Service',
'IT-Customer Support',
'IT-Customer Support',
'IT-Database Administration',
'IT-Enterprise Software',
'IT-Hardware Engineer',
'IT-Hardware/Network Install',
'IT-Internet',
'IT-International Carrier',
'IT-Intranet',
'IT-Local Operator',
'IT-Networking',
'IT-Others (Please Specify)',
'IT-Sales & Marketing',
'IT-SAP',
'IT-Software Application',
'IT-Solution',
'IT-System Software Install',
'IT-System Admin(Multiple Site)',
'IT-System Admin(Single Site)',
'IT-System Analysis & Design',
'IT-Systems Consultant',
'IT-Systems Engineer',
'IT-System Integration',
'IT-System Operator',
'Joint Venture Operation',
'Lawyer',
'Leasing',
'Legal Advisor',
'Librarian',
'Logistics',
'Merger & Acquisition',
'Macau Experience',
'Merchandiser',
'Miscellaneous',
'Marketing',
'Mandatory Provident Fund',
'Multi-Media',
'Nutrition',
'Operation',
'Options',
'Orthopaedic',
'Profit & Loss',
'Public Affairs / Corp. Comm.',
'Paediatric',
'Pastry Chef',
'Public Relation',
'Pre Sales',
'Product Development',
'Producer',
'Project Management',
'Proof Reader',
'Production Planning',
'Property Mgt',
'Purchasing',
'Quality Assurance',
'Quality Control',
'Radiology',
'Regional Experience',
'Reporter',
'Researcher',
'Retail Management',
'Safety',
'Sales',
'Scientist',
'Secretary',
'Semicon - Assembly',
'Semicon - EDA(Equip Design)',
'Semicon - Equipment',
'Semicon - Fabless',
'Semicon - Foundries',
'Semicon - FPD(Flat Panel Disp)',
'Semicon - HW',
'Semicon - IC(Integrated Chip)',
'Semicon - IDM(Int Dev Manufac)',
'Semicon - PCB(Prntd Cir Board)',
'Semicon - Solution Sales',
'Semicon - SW',
'Semicon - Testing',
'Shipping Operations',
'S&M - Account Manager',
'S&M - Brand Management',
'S&M - Direct Selling',
'S&M - Distribution',
'S&M - E Marketing',
'S&M - Event Marketing',
'S&M - Exhibition',
'S&M - Key Accounts',
'S&M - Pricing',
'S&M - Product',
'S&M - Promotions',
'S&M - Service Sales',
'S&M - TeleMarketing',
'Ref to Merchandising & Purchas',
'Start Up',
'Strategic & Policy Planning',
'Ref to Purchase or Logistics',
'Surgical',
'Surveyor',
'Teacher',
'Technician / Technical Support',
'Technologist',
'Technical Writer',
'Telecom - Broadband',
'Telecom - CCTV',
'Telecom - Datastorage',
'Telecom - Satellite',
'Telecom - Infrastructure',
'Telecom - Mobile HandSet',
'Telecom - Network',
'Telecom - Solutions',
'Telecom - Service Sales',
'Telecom - Technical Support',
'Telecom - Value Added Service',
'Telecom - Voice Over IP',
'Telecom - Wireless',
'Training',
'Translator',
'Textile Technologist',
'Underwriter',
'Visual Merchanising',
];

export default skills